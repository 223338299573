import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import FullReportLayout from '../../../components/layouts/FullReportLayout'

import imageDotChart from '../../../assets/images/report/dot-chart.png'

const Div = styled.div`
  .head-category {
    > .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 56px;
      line-height: 48px;
      color: var(--off-black);
    }

    > .sub-title {
      margin-top: 16px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 24px;
      line-height: 32px;
      color: var(--off-black);
    }

    > .desc {
      margin-top: 8px;
    }

    .summary-wrapper {
      display: flex;
      margin-top: 56px;

      .left {
        flex: 1;
        font-size: 18px;

        > div {
          position: relative;
          padding-left: 24px;
          margin-bottom: 16px;

          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            left: 0;
            top: 6px;
            border-radius: 50%;
          }

          &.red:after {
            background: #ea4335;
          }

          &.yellow:after {
            background: #fbbc04;
          }

          &.green:after {
            background: #34a853;
          }
        }
      }

      .right {
        flex: 1;
        max-width: 430px;

        img {
          width: 100%;
        }
      }
    }
  }

  .category-wrapper {
    .category-type {
      margin-top: 64px;

      .type-title {
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 56px;
        line-height: 48px;
        color: var(--off-black);
        border-left: 8px solid #f03f3b;
        padding-left: 16px;
      }

      .type-desc {
        margin-top: 16px;
      }

      .type-list {
        margin-top: 18px;

        > div {
          margin-top: 8px;

          .circle {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #f03f3b;
            color: var(--off-white);
            margin-right: 12px;
            text-align: center;
          }
        }
      }

      .type-sub-desc {
        margin-top: 24px;
      }

      .list-wrapper {
        margin-top: 16px;

        .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 18px 0;
          border-bottom: 1px solid var(--off-black);

          .left {
            .sub-category {
              font-family: 'kelson_sansbold';
              text-transform: uppercase;
              font-size: 24px;
              line-height: 32px;
              color: var(--off-black);
            }

            .detail {
              font-size: 14px;
              color: #f03f3b;
            }
          }

          .link {
            padding: 6px 35px;
            font-family: 'kelson_sansbold';
            text-transform: uppercase;
            font-size: 14px;
            line-height: 20px;
            color: var(--off-black);
            border: 1px solid var(--off-black);
            text-decoration: none;
          }
        }
      }

      &.type-yellow {
        .type-title {
          border-color: #ffb800 !important;
        }

        .type-list .circle {
          background: #ffb800 !important;
        }

        .list-wrapper .detail {
          color: #ffb800 !important;
        }
      }

      &.type-green {
        .type-title {
          border-color: #27ae8e !important;
        }

        .type-list .circle {
          background: #27ae8e !important;
        }

        .list-wrapper .detail {
          color: #27ae8e !important;
        }
      }
    }
  }
`

const FullReportCategory = () => {
  const { category } = useParams()
  const { snapshotDetailReport } = useSelector((state) => state.report)
  // console.log('category', category)
  // console.log('snapshotDetailReport', snapshotDetailReport)
  const report = snapshotDetailReport?.domain_detail?.find((item) =>
    item?.domain?.toLowerCase().includes(category)
  )

  const summary = report?.summary || []
  const critical = report?.critical || {}
  const average = report?.average || {}
  const fine = report?.fine || {}

  // console.log('report', report)

  return (
    <FullReportLayout>
      <Div>
        <div className="head-category">
          <div className="title">{report?.domain}</div>
          <div className="sub-title">
            {/* Your Skin Risk & Abilities */}
            Lorem ipsum dolor sit amet consectetur.
          </div>
          <div className="desc">
            {/* เปิดเผยแนวโน้มสุขภาพผิวของคุณจาก DNA ตรวจสอบความไวของผิวต่อปัจจัยรบกวนต่างๆ ที่ทําลายผิว
            ค้นหาความเสี่ยงที่คุณควรหลีกเลี่ยง เป็นพิเศษ และดูวิธีการดูแลผิวที่เหมาะกับ DNA ของคุณ */}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi consectetur nihil esse
            facilis, temporibus autem dolores eos ipsa provident explicabo voluptatibus tenetur.
          </div>
          <div className="summary-wrapper">
            <div className="left">
              {summary[0] && (
                <div className="green">
                  พบ {summary[0]?.count} {summary[0]?.result_tag}
                </div>
              )}
              {summary[1] && (
                <div className="yellow">
                  พบ {summary[1]?.count} {summary[1]?.result_tag}
                </div>
              )}
              {summary[2] && (
                <div className="red">
                  พบ {summary[2]?.count} {summary[2]?.result_tag} คือ
                </div>
              )}
            </div>
            <div className="right">
              <img src={imageDotChart} />
            </div>
          </div>
        </div>
        <div className="category-wrapper">
          {critical?.detail?.length > 0 && (
            <div className="category-type type-red">
              <div className="type-title">Critical</div>
              <div className="type-desc">Base on your genetic results. you have</div>
              <div className="type-list">
                <div>
                  <span className="circle">{summary[2]?.count}</span>
                  <span>{summary[2]?.result_tag}</span>
                </div>
              </div>
              <div className="type-sub-desc">
                {/* Access Full report below to get your full genetic result, our recommendation to
              improve your Skin Health. */}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam incidunt est ipsum
                rerum dolorem et possimus ipsam amet eos quae.
              </div>
              <div className="list-wrapper">
                {critical?.detail?.map((item, index) => (
                  <div className="list-item" key={`critical-detail: ${index}`}>
                    <div className="left">
                      <div className="sub-category">{item?.topic_th}</div>
                      <div className="detail">{item?.result_th_description}</div>
                    </div>
                    <Link
                      className="link"
                      to={`/full-report/${category}/${item?.domain}/${item?.dtm_id}`}
                    >
                      Full report
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          {average?.detail?.length > 0 && (
            <div className="category-type type-yellow">
              <div className="type-title">Average</div>
              <div className="type-desc">Base on your genetic results. you have</div>
              <div className="type-list">
                <div>
                  <span className="circle">{summary[1]?.count}</span>
                  <span>{summary[1]?.result_tag}</span>
                </div>
              </div>
              <div className="type-sub-desc">
                {/* Access Full report to get your full genetic result, our recommendation to maintian
                your Skin heath. */}
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus aut dolore
                cupiditate libero illum. Alias, rerum nobis! Commodi, architecto nam!
              </div>
              <div className="list-wrapper">
                {average?.detail?.map((item, index) => (
                  <div className="list-item" key={`average-detail: ${index}`}>
                    <div className="left">
                      <div className="sub-category">{item?.topic_th}</div>
                      <div className="detail">{item?.result_th_description}</div>
                    </div>
                    <Link
                      className="link"
                      to={`/full-report/${category}/${item?.domain}/${item?.dtm_id}`}
                    >
                      Full report
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
          {fine?.detail?.length > 0 && (
            <div className="category-type type-green">
              <div className="type-title">Fine</div>
              <div className="type-desc">Base on your genetic results. you have</div>
              <div className="type-list">
                <div>
                  <span className="circle">{summary[1]?.count}</span>
                  <span>{summary[0]?.result_tag}</span>
                </div>
              </div>
              <div className="type-sub-desc">
                {/* Access Full report below to get your full genetic result, our recommendation to
                improve your Skin Health. */}{' '}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit eligendi eos hic
                praesentium est repellat ducimus aperiam dolores repudiandae velit.
              </div>
              <div className="list-wrapper">
                {fine?.detail?.map((item, index) => (
                  <div className="list-item" key={`fine-detail: ${index}`}>
                    <div className="left">
                      <div className="sub-category">{item?.topic_th}</div>
                      <div className="detail">{item?.result_th_description}</div>
                    </div>
                    <Link
                      className="link"
                      to={`/full-report/${category}/${item?.domain}/${item?.dtm_id}`}
                    >
                      Full report
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Div>
    </FullReportLayout>
  )
}

export default FullReportCategory
