import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import TextField from '../components/form/TextField'
import NextButton from '../components/common/button/NextButton'
import { validateEmail } from 'utils/validation'
import { login } from 'redux/actions/auth'

import imageLogo from 'assets/images/logo-white.png'
import imageBackground from 'assets/images/login-bg.png'

const Div = styled.div`
  display: flex;
  height: 100vh;
  background: url('${imageBackground}') no-repeat center center;
  background-size: cover;

  > .left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 440px;
    }
  }

  > .right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-wrapper {
      width: 560px;
      padding: 63px 82px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.04);

      .title {
        font-size: 48px;
        font-family: 'kelson_sansbold';
        text-align: center;
      }

      form {
        margin-top: 68px;
      }

      .field-password {
        margin-top: 24px;
      }

      .bt-login {
        display: block;
        margin: 24px auto 0;
      }
    }
  }
`

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(login(email, password)).then(() => {
        history.replace('/report/1')
      })
    },
    [dispatch, email, history, password]
  )

  return (
    <Div>
      <div className="left">
        <img className="logo" src={imageLogo} />
      </div>
      <div className="right">
        <div className="login-wrapper">
          <div className="title">Fusion Website</div>
          <form onSubmit={handleLogin}>
            <TextField
              className="field-email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              className="field-password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <NextButton
              className="bt-login"
              type="submit"
              disabled={!validateEmail(email) || !password}
              fullWidth
            >
              Login to Fusion Tech
            </NextButton>
          </form>
        </div>
      </div>
    </Div>
  )
}

export default Login
