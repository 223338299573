import React from 'react'
import { useHistory } from 'react-router-dom'
import NextButton from '../components/common/button/NextButton'

const PageNotFound = () => {
  const history = useHistory()

  const goHomePage = () => {
    const accessToken = localStorage.getItem('access_token')

    if (accessToken) {
      history.push('/report/1/MM05QSMDC')
    } else history.replace('/login')
  }
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>
        404 Page Not Found!
        <br />
        <NextButton onClick={() => goHomePage()}>Go to Home Page</NextButton>
      </h1>
    </div>
  )
}

export default PageNotFound
