import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import MainLayout from './MainLayout'

const Div = styled.div`
  .main-menu {
    .menu-product,
    .menu-register,
    .menu-our-team,
    .menu-blog {
      display: none;
    }
  }

  .main-content {
    border-top: 1px solid var(--line);
  }

  .report-layout {
    width: 100%;
    max-width: 1440px;
    padding: 80px;
    margin: 0 auto;
  }

  .report-content {
    display: flex;
    margin: 0 -32px;

    .report-menu {
      width: 160px;
      min-width: 160px;
      margin: 0 32px;

      a {
        display: block;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 0;
        color: var(--off-black);
        border-bottom: 1px solid var(--line);
        text-decoration: none;

        &.active,
        &:hover {
          font-weight: bold;
          border-bottom: 1px solid var(--off-black);
        }

        &:first-child {
          padding-top: 0;
        }
      }
    }

    .report-body {
      flex: 1;
      margin: 0 32px;
    }
  }
`

const FullReportLayout = ({ children, ...props }) => {
  return (
    <Div>
      <MainLayout {...props}>
        <div className="report-layout">
          <div className="report-content">
            <div className="report-menu">
              <NavLink activeClassName="active" to="/full-report/sleep">
                Sleep & Stress
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/personality">
                Personality & Work Performance
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/performance">
                PERFORMANCE FUNCTIONAL
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/health">
                Health
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/cancer">
                Cancer Risk
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/genetic">
                Genetic Disorder
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/origin">
                Origin & Fun Facts
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/beauty">
                Beauty & Skin
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/nutrition">
                Nutrition
              </NavLink>
              <NavLink activeClassName="active" to="/full-report/drug">
                Drug Response
              </NavLink>
            </div>
            <div className="report-body">{children}</div>
          </div>
        </div>
      </MainLayout>
    </Div>
  )
}

export default FullReportLayout
