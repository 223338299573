import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReportLayout from '../../components/layouts/ReportLayout'
import Button from '../../components/common/button/Button'
import NextButton from '../../components/common/button/NextButton'

import image1 from '../../assets/images/report/book-life1.png'
import image2 from '../../assets/images/report/book-life2.png'
import image3 from '../../assets/images/report/book-life3.png'
import image4 from '../../assets/images/report/book-life4.png'
import image5 from '../../assets/images/report/book-life5.png'
import image6 from '../../assets/images/report/book-life6.png'

import { getABookOfLife } from 'redux/actions/report'

const StyledBanner = styled.div`
  background: var(--off-black);
  padding: 72px 20px;

  > .wrapper {
    display: flex;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    color: var(--off-white);

    .left {
      flex: 1;

      .title {
        font-size: 46px;
        height: 56px;
      }

      .total-report {
        font-size: 16px;

        span {
          font-size: 160px;
          line-height: 192px;
        }
      }

      button {
        border: 1px solid var(--off-white);
        width: 330px;
      }
    }

    .right {
      flex: 1;
      margin-top: 200px;

      .catagory {
        font-size: 160px;
        line-height: 142px;

        span {
          font-size: 24px;
          line-height: 32px;
          vertical-align: top;
        }
      }

      button {
        margin-top: 32px;
        width: 320px;
      }
    }
  }
`

const Div = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0 -24px;

  .left {
    flex: 1;
    margin: 0 24px 32px;

    .image-wrapper {
      display: flex;
      margin: 0 -5px;

      > img {
        width: 100%;
        min-width: 0;
        margin: 0 5px;
      }
    }
  }

  .right {
    flex: 1;
    margin: 0 24px 32px;

    button {
      width: 100%;
      margin-top: 40px;
    }
  }

  .category-wrapper {
    margin-top: 40px;

    .list {
      margin-top: 16px;
    }

    + .category-wrapper {
      padding-top: 40px;
      border-top: 1px solid var(--off-black);
    }

    + img {
      margin-top: 40px;
      width: 100%;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .category-link {
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 24px;
    line-height: 32px;
    color: var(--text-title);
    text-decoration: none;

    svg {
      margin-right: 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`

const ReportBookOfLife = () => {
  const history = useHistory()
  const { simpleId } = useParams()

  const dispatch = useDispatch()
  const { bookOfLifeReport } = useSelector((state) => state.report)
  const contentList =
    bookOfLifeReport?.detail?.map((item) => ({
      domain_mapping: item?.domain_mapping,
      topic_th: item?.topic_th,
      total: item?.total,
    })) || []

  useEffect(() => {
    dispatch(getABookOfLife(simpleId))
  }, [simpleId])

  const Banner = () => {
    return (
      <StyledBanner>
        <div className="wrapper">
          <div className="left">
            <div className="title">A book of life</div>
            <div className="total-report">
              <span>{bookOfLifeReport?.total_topic}</span> reports
            </div>
            <Button onClick={() => history.push('/special-consult')}>
              Special Consult For you{' '}
            </Button>
          </div>
          <div className="right">
            <div className="catagory">
              {bookOfLifeReport?.total_domain}
              <span>/{bookOfLifeReport?.total_topic}</span>
            </div>
            <NextButton onClick={() => history.push('/report/snapshot-your-health')} invert>
              Snap Shot Your Health
            </NextButton>
          </div>
        </div>
      </StyledBanner>
    )
  }

  const CategoryLink = ({ children, ...props }) => {
    return (
      <HashLink className="category-link" {...props}>
        <FontAwesomeIcon icon={['fas', 'arrow-right']} />
        {children}
      </HashLink>
    )
  }

  return (
    <ReportLayout header={<Banner />}>
      <Div>
        <div className="left">
          <div className="image-wrapper">
            <img src={image3} />
            <img src={image4} />
          </div>
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#health">
              {contentList[4]?.domain_mapping} ({contentList[4]?.total})
            </CategoryLink>
            <div className="list">{contentList[4]?.topic_th}</div>
          </div>
          <img src={image5} />
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#origin">
              {contentList[6]?.domain_mapping} ({contentList[6]?.total})
            </CategoryLink>
            <div className="list">{contentList[6]?.topic_th}</div>
          </div>
          <img src={image6} />
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#nutrition">
              {contentList[5]?.domain_mapping} ({contentList[5]?.total})
            </CategoryLink>
            <div className="list">{contentList[5]?.topic_th}</div>
          </div>
        </div>
        <div className="right">
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#sleep">
              {contentList[9]?.domain_mapping} ({contentList[9]?.total})
            </CategoryLink>
            <div className="list">{contentList[9]?.topic_th}</div>
          </div>
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#personality">
              {contentList[8]?.domain_mapping} ({contentList[8]?.total})
            </CategoryLink>
            <div className="list">{contentList[8]?.topic_th}</div>
          </div>
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#performance">
              {contentList[7]?.domain_mapping} ({contentList[7]?.total})
            </CategoryLink>
            <div className="list">{contentList[7]?.topic_th}</div>
          </div>
          <img src={image1} />
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#cancer">
              {contentList[1]?.domain_mapping} ({contentList[1]?.total})
            </CategoryLink>
            <div className="list">{contentList[1]?.topic_th}</div>
          </div>
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#genetic">
              {contentList[3]?.domain_mapping} ({contentList[3]?.total})
            </CategoryLink>
            <div className="list">{contentList[3]?.topic_th}</div>
          </div>
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#beauty">
              {contentList[0]?.domain_mapping} ({contentList[0]?.total})
            </CategoryLink>
            <div className="list">{contentList[0]?.topic_th}</div>
          </div>
          <img src={image2} />
          <div className="category-wrapper">
            <CategoryLink to="/snapshot-your-health#drug">
              {contentList[2]?.domain_mapping} ({contentList[2]?.total})
            </CategoryLink>
            <div className="list">{contentList[2]?.topic_th}</div>
          </div>
          <NextButton onClick={() => history.push('/start-new-journey')}>
            Follow Your Journey
          </NextButton>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportBookOfLife
