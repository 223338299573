import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  align-items: center;
  label {
    display: inline-block;
    font-size: 16px;
    flex: 1;
  }
  input {
    display: inline-block;
    font-size: 22px;
    height: 45px;
    flex: 2;
  }
`

const TextField = ({ className, label, type = 'text', ...props }) => {
  return (
    <Div className={className} fullWidth>
      {Boolean(label) && <label>{label}</label>}
      <input type={type} {...props} />
    </Div>
  )
}

export default TextField
