import styled from 'styled-components'
import { Link } from 'react-router-dom'

import imgLogo from '../../../assets/images/logo-black.png'
import imgMobile from '../../../assets/images/logo-mobile.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import ReportMenuMobile from 'components/layouts/components/ReportMenuMobile'

const Div = styled.header`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    z-index: 99;
    max-width: 1440px;
    padding: 24px 56px;
    margin: 0 auto;

    @media screen and (max-width: 416px) {
      max-width: 100%;
      padding: 10px 20px;
      width: 100%;
      border-bottom: 1px solid var(--line);
    }

    .logo {
      width: 120px;

      @media screen and (max-width: 416px) {
        display: none;
      }
    }

    .m-hamburger {
      display: none;
      @media screen and (max-width: 416px) {
        display: block;
      }
    }
    .m-logo-version {
      display: none;

      @media screen and (max-width: 416px) {
        display: inline-flex;
        align-items: center;
        gap: 5px;
      }

      .m-logo__img {
        width: 35px;
        display: none;
        @media screen and (max-width: 416px) {
          display: block;
        }
      }
      .m-logo__label {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    .main-menu {
      display: flex;
      align-items: center;

      > a {
        font-family: 'kelson_sansbold';
        font-size: 16px;
        margin-left: 20px;
        color: var(--off-black);
        text-decoration: none;
        text-transform: uppercase;

        &.menu-noti,
        &.menu-bag {
          margin-left: 26px;

          > img {
            display: block;
            width: 18px;
          }
        }

        &.menu-profile {
          display: block;
          min-width: 32px;
          height: 32px;
          padding: 4px;
          margin-left: 26px;
          text-align: center;
          color: var(--disabled);
          background-color: var(--text-body);
          border-radius: 50%;
          text-decoration: none !important;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  nav {
    display: none;

    @media screen and (max-width: 416px) {
      display: block;
      padding: 10px 20px;
      /* height: 310px; */
      z-index: 1;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: transform 0.3s ease-in-out;
      transform: translateX(100%);
      background-color: var(--off-white);
      margin-top: 50px;
      &.active {
        transform: translateX(0%);
      }
    }
  }
`

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  const toggleNavMenu = () => {
    setToggleMenu(!toggleMenu)
  }

  return (
    <Div>
      <div className="container">
        <img className="logo" src={imgLogo} />
        <div className="m-logo-version">
          <img className="m-logo__img" src={imgMobile} />
          <span className="m-logo__label">Code Blue</span>
        </div>
        {/* <div className="main-menu">
        <Link className="menu-product" to="#">
          Products
        </Link>
        <Link className="menu-register" to="#">
          Register test kits
        </Link>
        <Link className="menu-our-team" to="#">
          Our team
        </Link>
        <Link className="menu-blog" to="#">
          Blog
        </Link>
        <Link className="menu-noti" to="#">
          <img src={iconNoti} />
        </Link>
        <Link className="menu-bag" to="#">
          <img src={iconBag} />
        </Link>
        <Link className="menu-profile" to="#">
          N
        </Link>
      </div> */}
        <div className="m-hamburger">
          {!toggleMenu ? (
            <FontAwesomeIcon
              color="#667085"
              icon={['fas', 'bars']}
              size="lg"
              onClick={() => toggleNavMenu()}
            />
          ) : (
            <i class="fa fa-close" aria-hidden="true" onClick={() => toggleNavMenu()} />
          )}
        </div>
      </div>
      <nav className={`${toggleMenu ? 'active' : ''}`}>
        <ReportMenuMobile isNavMenu />
      </nav>
    </Div>
  )
}

export default Header
