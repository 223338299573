import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import noImage from '../../assets/images/report/no-image.png'
import imageBanner from '../../assets/images/report/banner-discover-yourself.png'

import { getPageDiscover, getReportInfo } from 'redux/actions/report'

const Div = styled.div`
  @media screen and (max-width: 416px) {
    padding-top: 50px;
  }

  > .title {
    /* font-family: 'kelson_sansbold'; */
    font-family: 'Roboto', 'Noto Sans Thai';
    font-size: 56px;
    line-height: 56px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--off-black);
    text-align: center;

    @media screen and (max-width: 416px) {
      font-size: 36px;
    }
  }

  > .sub-title {
    margin-top: 32px;
    font-family: 'kelson_sansbold';
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
  }

  > .banner {
    margin-top: 64px;
    width: 100%;
    object-fit: cover;
    height: 700px;

    @media screen and (max-width: 416px) {
      height: 275px;
    }
  }

  > .desc {
    margin-top: 64px;
    font-size: 1.5rem;

    @media screen and (max-width: 416px) {
      margin-top: 32px;
    }
  }

  .section-bottom {
    margin-top: 56px;
    padding-top: 58px;
    border-top: 1px solid var(--off-black);

    @media screen and (max-width: 416px) {
      border-top: 1px solid #1717141c;
    }
    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      @media screen and (max-width: 416px) {
        flex-direction: column;
      }

      .left {
        flex: 1;
        margin: 0 24px;
        .title {
          max-width: 75%;
          font-size: 3rem;
          font-weight: bold;
          text-transform: uppercase;

          @media screen and (max-width: 416px) {
            font-size: 30px;
            width: 100%;
            max-width: 100%;
            margin-bottom: 24px;
          }
        }
      }

      .right {
        flex: 1;
        margin: 0 24px;

        button {
          width: 100%;
          margin-top: 48px;
        }
        .content {
          font-size: 1.25rem;
          margin-bottom: 32px;
          @media screen and (max-width: 416px) {
            margin-bottom: 50px;

            :last-child {
              margin-bottom: 80px;
            }
          }
        }
        .title-content {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 2rem;

          @media screen and (max-width: 416px) {
            font-size: 22px;
          }
        }
      }
    }
  }
`

const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 700px;
  background: #7a8fc5;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 416px) {
    height: auto;
    padding: 40px 20px;
  }

  .title {
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 5rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 416px) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }

  .title2 {
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 48px;
  }

  .desc {
    max-width: 900px;
    margin: 12px auto 0;
    font-size: 1.75rem;

    @media screen and (max-width: 416px) {
      font-size: 22px;
      text-align: left;
      width: 100%;
      font-weight: normal;
      max-width: 100%;
      margin: 0;
    }
  }
`

const StyledFooterImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 700px;
  background: ${(props) => `url(${props.bannerImage} ) no-repeat center center`};
  background-size: cover;
  background-position: center center;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 416px) {
    width: 100%;
    height: 275px;
    object-position: center;
  }
`

const ReportDiscoverYourself = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { recommendInfo } = useSelector((state) => state.report)
  const { userId } = useParams()

  useEffect(() => {
    fetchingReportInfo()
  }, [])

  const fetchingReportInfo = useCallback(() => {
    dispatch(getReportInfo(userId))
  }, [userId])

  useEffect(() => {
    console.log('recommendInfo', recommendInfo)
  }, [recommendInfo])

  const Banner = ({ bannerImage }) => {
    return (
      <StyledBanner bannerImage={bannerImage}>
        <div className="title">Healthy Always</div>
        <div className="desc format-info">{ReactHtmlParser(recommendInfo?.ending_content)}</div>
      </StyledBanner>
    )
  }

  const FooterImage = ({ bannerImage }) => {
    return <StyledFooterImage bannerImage={bannerImage} />
  }

  return (
    <ReportLayout
      footer={
        <Fragment>
          <FooterImage
            bannerImage={recommendInfo?.ending_image !== '' ? recommendInfo?.ending_image : noImage}
          />
          <Banner
            bannerImage={recommendInfo?.ending_image !== '' ? recommendInfo?.ending_image : noImage}
          />
        </Fragment>
      }
    >
      <Div>
        <div className="title">ผลกระทบกับชีวิตและสุขภาพ</div>
        <img
          className="banner"
          src={recommendInfo?.life_image !== '' ? recommendInfo?.life_image : noImage}
        />
        <div className="desc">{ReactHtmlParser(recommendInfo?.effect)}</div>
        <div className="section-bottom">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">คำแนะนำเพื่อปรับสภาพจิตใจให้สมดุล</div>
            </div>
            <div className="right">
              <div className="content">
                <div className="title-content">{recommendInfo?.recommend_p1_title}</div>
                {ReactHtmlParser(recommendInfo?.recommend_p1_content)}
              </div>
              <div className="content">
                <div className="title-content">{recommendInfo?.recommend_p2_title}</div>
                {ReactHtmlParser(recommendInfo?.recommend_p2_content)}
              </div>
              <div className="content">
                <div className="title-content">{recommendInfo?.recommend_p3_title}</div>
                {ReactHtmlParser(recommendInfo?.recommend_p3_content)}
              </div>
              <div className="content">
                <div className="title-content">{recommendInfo?.recommend_p4_title}</div>
                {ReactHtmlParser(recommendInfo?.recommend_p4_content)}
              </div>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportDiscoverYourself
