import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ReportLayout from '../../components/layouts/ReportLayout'

import imageChart from '../../assets/images/report/chart.png'

import { getSnapshotYourHealth, getSnapshotYourHealthDetail } from 'redux/actions/report'

const Div = styled.div`
  .summary-wrapper {
    display: flex;
    margin: 0 -64px;

    .left {
      flex: 1;
      margin: 0 64px;

      .sub-title {
        font-family: 'kelson_sansbold';
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
      }

      .title {
        margin-top: 8px;
        font-family: 'kelson_sansbold';
        font-size: 56px;
        line-height: 64px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
        font-size: 14px;
      }
    }

    .right {
      flex: 1;
      margin: 0 64px;

      .row {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-top: 1px solid var(--off-black);

        &:first-child {
          border-top: none;
        }
      }

      a {
        color: var(--off-black);
        text-decoration: none;

        svg {
          margin-right: 12px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .chart-wrapper {
    margin-top: 67px;

    .information-wrapper {
      display: flex;
      margin-top: 24px;

      .information {
        position: relative;
        flex: 1;
        padding-left: 32px;
        padding-right: 12px;

        &.red:after {
          background: #ea4335;
        }

        &.yellow:after {
          background: #fbbc04;
        }

        &.green:after {
          background: #34a853;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 0;
          left: 0;
        }
      }
    }

    .chart {
      margin-top: 32px;

      img {
        width: 100%;
      }
    }
  }

  .cetegory-wrapper {
    margin-top: 105px;

    .cetegory {
      display: flex;
      margin: 0 -8px;

      .left {
        flex: 1;
        margin: 0 8px 64px;
      }

      .right {
        flex: 1;
        margin: 0 8px 64px;
      }

      .title {
        margin-bottom: 24px;
        font-family: 'kelson_sansbold';
        font-size: 48px;
        line-height: 56px;
        text-transform: uppercase;
        color: var(--off-black);
      }

      .btn-full-report {
        padding: 8px 24px;
        font-family: 'kelson_sansbold';
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--off-black);
        border: 1px solid var(--off-black);
        text-decoration: none;
      }

      .information {
        position: relative;
        font-size: 18px;
        padding: 9px 8px 9px 32px;
        border-top: 1px solid var(--off-black);

        &.red:after {
          background: #f03f3b;
        }

        &.yellow:after {
          background: #ffb800;
        }

        &.green:after {
          background: #27ae8e;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          top: 15px;
          left: 6px;
        }
      }

      .detail {
        margin-top: 24px;
      }
    }
  }
`

const CategoryLink = ({ children, ...props }) => {
  return (
    <HashLink {...props}>
      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
      {children}
    </HashLink>
  )
}

const ReportSnapshotHealth = () => {
  const { simpleId } = useParams()
  const dispatch = useDispatch()
  const { snapshotReport } = useSelector((state) => state.report)
  const subDomainList = snapshotReport?.quick_summary || []
  const snapshotDetailList = snapshotReport?.snapshot_detail_total || []
  const [totalCountDomain, setTotalCountDomain] = useState(0)

  useEffect(() => {
    dispatch(getSnapshotYourHealth(simpleId))
    dispatch(getSnapshotYourHealthDetail(simpleId))
  }, [simpleId])

  useEffect(() => {
    const total = snapshotReport?.quick_summary
      ?.map((domain) => domain)
      .reduce((n, { total }) => n + Number(total), 0)

    setTotalCountDomain(total)
  }, [snapshotReport?.quick_summary])

  return (
    <ReportLayout>
      <Div>
        <div className="summary-wrapper">
          <div className="left">
            <div className="sub-title">Report</div>
            <div className="title">Quick Summary</div>
            <div className="desc">
              บทสรุปการตรวจสอบ ‘พื้นฐานสุขภาพ และศักยภาพของร่างกาย’ ที่ซ่อนอยู่ใน DNA ของคุณ{' '}
              {snapshotReport?.firstname} {snapshotReport?.lastname} (Sample ID :{' '}
              {snapshotReport?.simple_id}) จํานวน {totalCountDomain} รายการ แบ่งเป็น{' '}
              {subDomainList?.length} หมวด
            </div>
          </div>
          <div className="right">
            <div className="row">
              <div className="category">หมวดหมู่</div>
              <div className="amount">จำนวนรายการตรวจ</div>
            </div>
            {subDomainList.map(({ domain_mapping, total }, index) => {
              const link = domain_mapping?.split(' ')[0].toLowerCase()
              return (
                <div className="row" key={`subdomain-${index}`}>
                  <div className="category">
                    <CategoryLink to={`#${link}`}>{domain_mapping}</CategoryLink>
                  </div>
                  <div className="amount">{total} รายการ</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="chart-wrapper">
          <div className="title">
            เพื่อให้คุณเข้าใจตัวเองได้อย่างรวดเร็ว ว่ามีความเสี่ยงต่อปัญหาสุขภาพต่างๆ มากน้อยอย่างไร
            หรือมีศักยภาพใดบ้างที่โดดเด่น FUSION ได้จัดระดับความสําคัญ พร้อมแสดงสัญลักษณ์ไว้ดังนี้
          </div>
          <div className="information-wrapper">
            <div className="information red">
              สัญลักษณ์สีแดง คือ “ควรให้ความใส่ใจเป็นพิเศษ” อาจเป็นความเสี่ยงสุขภาพที่มากกว่า
              หรือประสิทธิภาพของร่างกายที่ต่ํากว่า ค่าเฉลี่ยคนทั่วไป
            </div>
            <div className="information yellow">
              สัญลักษณ์สีเหลือง คือ “ผลลัพธ์ความเสี่ยงสุขภาพ” หรือประสิทธิภาพของร่างกาย
              อยู่ในระดับค่าเฉลี่ยของคนทั่วไป
            </div>
            <div className="information green">
              สัญลักษณ์สีเขียว คือ “ดีกว่าค่าเฉลี่ยคนทั่วไป” อาจเป็นความเสี่ยงสุขภาพที่ต่ํากว่าหรือ
              เป็นประสิทธิภาพที่สูงกว่าค่าเฉลี่ยคนทั่วไป
            </div>
          </div>
          <div className="chart">
            <img src={imageChart} />
          </div>
        </div>
        <div className="cetegory-wrapper">
          {snapshotDetailList.map(({ domain, total, detail }, index) => {
            const idTag = domain?.split(' ')[0].toLowerCase()

            return (
              <div id={idTag} className="cetegory" key={`snapshot-detail-${index}`}>
                <div className="left">
                  <div className="title">{domain}</div>
                  <NavLink className="btn-full-report" to={`/full-report/${idTag}`}>
                    View full report
                  </NavLink>
                </div>
                <div className="right">
                  <div className="information green">
                    พบ {total[0]?.count} {total[0]?.result_tag}
                  </div>
                  {total[1] && (
                    <div className="information yellow">
                      พบ {total[1]?.count} {total[1]?.result_tag}
                    </div>
                  )}
                  {total[2] && (
                    <div className="information red">
                      พบ {total[2]?.count} {total[2]?.result_tag} คือ
                    </div>
                  )}
                  <div className="detail">
                    {detail.map(({ topic_th, result_th_description, suggestion_short }, index) => (
                      <p key={`topic-detail-${index}}`}>
                        {index + 1} {topic_th}
                        <br />
                        ผลลัพธ์ : {result_th_description}
                        <br />
                        คําแนะนํา : {suggestion_short || '-'}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportSnapshotHealth
