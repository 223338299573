import { LOGIN } from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading'
import { postAPI } from 'utils/api'

export const login =
  (email = 's_chanyut@hotmail.com', password = '@Chaibird30315') =>
  (dispatch) => {
    dispatch(showLoading())

    return postAPI({
      url: 'login',
      data: {
        email,
        password,
        strategy: 'local',
      },
    })
      .then(({ accessToken, user }) => {
        localStorage.setItem('access_token', accessToken)
        localStorage.setItem('user', JSON.stringify(user))
        return dispatch({ type: LOGIN.SUCCESS })
      })
      .catch((error) => dispatch({ type: LOGIN.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading())
      })
  }

export const logout = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('user')
}
