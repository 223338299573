import styled from 'styled-components'

import MainLayout from './MainLayout'
import ReportMenu from './components/ReportMenu'

const Div = styled.div`
  @media screen and (max-width: 416px) {
    height: 100%;
  }
  .report-menu-wrapper {
    width: 100%;
    border-top: 1px solid var(--line);
    border-bottom: 1px solid var(--line);

    @media screen and (max-width: 416px) {
      display: none;
    }
  }

  .report-content {
    width: 100%;
    margin: 0 auto;

    .report-body {
      padding: 80px;
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;

      @media screen and (max-width: 416px) {
        padding: 20px;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
      }
    }
  }
`

const ReportLayout = ({ header, footer, children, ...props }) => {
  return (
    <MainLayout {...props}>
      <Div>
        <div className="report-menu-wrapper">
          <ReportMenu />
        </div>
        <div className="report-content">
          {header && <div className="report-header">{header}</div>}
          {children && <div className="report-body">{children}</div>}
          {footer && <div className="report-header">{footer}</div>}
        </div>
      </Div>
    </MainLayout>
  )
}

export default ReportLayout
