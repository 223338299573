import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import noImage from '../../assets/images/report/no-image.png'
import imageBanner from '../../assets/images/report/banner-learn-more.png'

import { getPageExploreOurBody, getReportInfo } from 'redux/actions/report'

const Div = styled.div`
  @media screen and (max-width: 416px) {
    padding-top: 50px;
  }

  .section {
    .section-wrapper {
      display: flex;
      margin: 0 -24px;

      @media screen and (max-width: 416px) {
        flex-direction: column;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .left {
      flex: 1;
      margin: 0 24px 24px;
    }

    .right {
      flex: 1;
      margin: 0 24px 24px;
    }

    .title {
      /* font-family: 'kelson_sansbold'; */
      font-family: 'Roboto', 'Noto Sans Thai';
      font-size: 56px;
      line-height: 64px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: 416px) {
        font-size: 38px;
      }
    }

    .sub-title {
      /* font-family: 'kelson_sansbold'; */
      font-family: 'Roboto', 'Noto Sans Thai';
      font-size: 32px;
      line-height: 45px;
      width: 60%;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: 416px) {
        font-size: 28px;
        width: 80%;
      }
    }

    &.section-top {
      .sub-title {
        margin-top: 212px;

        @media screen and (max-width: 416px) {
          margin-top: 15px;
        }
      }

      .content {
        margin-top: 16px;
        font-size: 20px;

        @media screen and (max-width: 416px) {
          margin-top: 60px;
          color: #697388;
          line-height: 1.5;
        }
      }
      .left {
        img {
          display: none;

          @media screen and (max-width: 416px) {
            display: block;
            margin-top: 60px;
          }
        }
      }

      .right {
        @media screen and (max-width: 416px) {
          display: none;
        }
      }
    }

    &.section-bottom {
      margin-top: 80px;
      padding-top: 73px;
      border-top: 1px solid var(--off-black);

      @media screen and (max-width: 416px) {
        margin-top: 20px;
        border-top: 1px solid #1717141c;
        padding-top: 40px;
      }

      .title {
        width: 80%;

        @media screen and (max-width: 416px) {
          width: 90%;
          font-size: 26px;
          line-height: 1.5;
          border-left: 3px solid #4a03ee;
          padding-left: 20px;
        }
      }
      .group {
        margin-top: 48px;
        font-size: 20px;
        width: 90%;

        @media screen and (max-width: 416px) {
          margin-top: 60px;
        }

        .group-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        &:first-child {
          margin-top: 0px;

          @media screen and (max-width: 416px) {
            margin-top: 40px;
          }
        }
      }

      button {
        margin-top: 56px;
        width: 100%;

        @media screen and (max-width: 416px) {
          border-radius: 8px;
          /* border: 1px solid #4a03ee; */
        }
      }
    }
  }
`

const ReportLearnFushion = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { personalityInfo } = useSelector((state) => state.report)

  const { userId } = useParams()

  useEffect(() => {
    fetchingReportInfo()
  }, [])

  const fetchingReportInfo = useCallback(() => {
    dispatch(getReportInfo(userId))
  }, [userId])

  useEffect(() => {
    console.log('personalityInfo', personalityInfo)
  }, [personalityInfo])

  return (
    <ReportLayout>
      <Div>
        <div className="section section-top">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">{personalityInfo?.sec1_title}</div>
              <div className="sub-title">{personalityInfo?.sec1_body_highlight}</div>
              <img src={personalityInfo?.image_url !== '' ? personalityInfo?.image_url : noImage} />

              <div className="content format-info">
                {ReactHtmlParser(personalityInfo?.sec1_body_content)}
              </div>
            </div>
            <div className="right">
              <img src={personalityInfo?.image_url !== '' ? personalityInfo?.image_url : noImage} />
            </div>
          </div>
        </div>
        <div className="section section-bottom">
          <div className="section-wrapper">
            <div className="left">
              <div className="title">{personalityInfo?.sec2_title}</div>
            </div>
            <div className="right">
              <div className="content">
                <div className="group format-info">
                  <div className="group-title">{personalityInfo?.sec2_p1_title}</div>
                  {ReactHtmlParser(personalityInfo?.sec2_p1_content)}
                </div>
                <div className="group">
                  <div className="group-title">{personalityInfo?.sec2_p2_title}</div>
                  {ReactHtmlParser(personalityInfo?.sec2_p2_content)}
                </div>
              </div>
              <NextButton onClick={() => history.push(`/enneagram/${userId}`)}>
                What is Enneagram
              </NextButton>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportLearnFushion
