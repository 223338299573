import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'

const Div = styled.div`
  display: flex;
  margin: 0 auto;
  /* justify-content: center; */
  max-width: 1440px;
  padding: 0 56px;
  a {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 80px;
    padding: 12px;
    font-family: 'kelson_sansbold';
    /* font-family: 'Roboto', 'Noto Sans Thai'; */
    font-size: 0.9rem;
    color: var(--off-black);
    text-decoration: none;
    text-transform: uppercase;
    border-left: 1px solid var(--line);

    &.active {
      color: var(--off-white);
      background-color: var(--off-black);
    }

    &:last-child {
      border-right: 1px solid var(--line);
    }
  }
`

const ReportMenu = () => {
  const { userInfo, enneagramInfo } = useSelector((state) => state.report)
  const { userId } = useParams()

  return (
    <Div>
      <NavLink to={`/report/${userId}`} activeClassName="active" exact>
        {userInfo?.fname} {userInfo?.lname?.substring(0, 1)}.
      </NavLink>
      <NavLink to={`/personality/${userId}`} activeClassName="active">
        Personality for better life
      </NavLink>
      <NavLink to={`/enneagram/${userId}`} activeClassName="active">
        {enneagramInfo?.banner_title}
      </NavLink>
      <NavLink to={`/result/${userId}`} activeClassName="active">
        Your result
      </NavLink>
      <NavLink to={`/recommend/${userId}`} activeClassName="active">
        Life & Recommendation
      </NavLink>
      {/* <NavLink to="/book-of-life/MM05GT52C" activeClassName="active">
        A Book of life
      </NavLink>
      <NavLink to="/snapshot-your-health/MM05GT52C" activeClassName="active">
        Snapshot your health
      </NavLink>
      <NavLink to="/special-consult" activeClassName="active">
        Special consult for you
      </NavLink>
      <NavLink to="/start-new-journey" activeClassName="active">
        Start new journey
      </NavLink> */}
    </Div>
  )
}

export default ReportMenu
