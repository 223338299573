import styled from 'styled-components'

const Div = styled.footer`
  padding: 14px;
  font-size: 16px;
  text-align: center;
  color: var(--off-black);

  @media screen and (max-width: 416px) {
  }
`

const Footer = () => {
  return <Div>COPY RIGHT | CODE BLUE</Div>
}

export default Footer
