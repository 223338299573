import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import noImage from '../../assets/images/report/no-image.png'

import { getPageOurPromise, getReportInfo } from 'redux/actions/report'
import { removeFirstWord } from 'utils/common'

const Div = styled.div`
  @media screen and (max-width: 416px) {
    padding-top: 20px;
  }

  > .title {
    font-family: 'kelson_sansbold';
    font-size: 32px;
    text-transform: uppercase;
  }

  > .desc {
    margin: 16px auto 0;
    max-width: 656px;
  }

  > .research-list {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;

    @media screen and (max-width: 416px) {
      flex-direction: column;
    }

    .offset {
      flex: 0.25;
    }
    .list {
      flex: 1;
      margin: 0 8px 20px;

      @media screen and (max-width: 416px) {
        :last-child {
          border-top: 1px solid #1717141c;
        }
      }
      img {
        display: none;
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        margin: 60px 0;

        @media screen and (max-width: 416px) {
          display: block;
        }
      }

      .label {
        margin-top: 36px;
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 416px) {
          font-size: 44px;
          line-height: 1.2;
        }
      }

      .label-2 {
        margin-top: 36px;
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 416px) {
          display: none;
        }
      }

      .m-label-2 {
        display: none;

        @media screen and (max-width: 416px) {
          display: block;
          margin-top: 50px;
          font-family: 'kelson_sansbold';
          text-transform: uppercase;
          font-size: 26px;
          margin-bottom: 1rem;
        }
      }

      .label-bottom {
        font-size: 2rem;
        margin-top: 36px;
        font-family: 'Roboto', 'Noto Sans Thai';
        text-transform: uppercase;
        font-weight: bold;

        @media screen and (max-width: 416px) {
          width: 90%;
          font-size: 26px;
          line-height: 1.5;
          border-left: 3px solid #4a03ee;
          padding-left: 20px;
        }
      }

      .desc {
        margin-top: 4px;
        font-size: 14px;
        font-size: 1.1rem;
        @media screen and (max-width: 416px) {
          display: none;
        }
      }

      & .m-desc {
        display: none;

        @media screen and (max-width: 416px) {
          display: block;
          margin-top: 4px;
          font-size: 14px;
          font-size: 1.1rem;
        }
      }

      > button {
        margin-top: 64px;
        width: 100%;

        @media screen and (max-width: 416px) {
          display: none;
        }
      }

      .m-button {
        display: none;
        border-radius: 8px;
        margin-top: 45px;
        /* border: 1px solid #4a03ee; */
        @media screen and (max-width: 416px) {
          display: block;
        }
      }
    }
  }
`

const StyledBanner = styled.div`
  background: ${(props) => `url(${props.bannerImage} ) no-repeat center center`};
  background-size: cover;
  background-position: center center;

  @media screen and (max-width: 416px) {
    display: none;
  }

  .text-wrapper {
    padding: 80px;
    max-width: 1440px;
    height: 800px;
    margin: 0 auto;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 32px;
    }

    .offset {
      flex: 0.25;
    }

    .title2 {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 72px;
      flex: 1;
      text-align: left;
      position: relative;
      top: -50px;
    }

    .desc {
      width: 450px;
      flex: 1;
      font-size: 1.5rem;
      text-align: left;
      position: relative;
      bottom: -50px;
    }
  }
`

const ReportOurPromise = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { enneagramInfo } = useSelector((state) => state.report)

  const [titleEn, setTitleEn] = useState('')
  const { userId } = useParams()

  useEffect(() => {
    fetchingReportInfo()
  }, [])

  useEffect(() => {
    if (enneagramInfo?.banner_title) {
    }
  }, [enneagramInfo?.banner_title])
  const fetchingReportInfo = useCallback(() => {
    dispatch(getReportInfo(userId))
  }, [userId])

  useEffect(() => {
    if (enneagramInfo?.banner_title) {
      let split = enneagramInfo?.banner_title?.split(' ')
      setTitleEn(split)
    }
  }, [enneagramInfo?.banner_title])

  const Banner = ({ bannerImage }) => {
    return (
      <StyledBanner bannerImage={bannerImage}>
        <div className="text-wrapper">
          <div className="title2">
            {titleEn[0]} {titleEn[1]} <br />
            {titleEn[2]}
          </div>
          <div className="offset"></div>
          <div className="desc">{ReactHtmlParser(enneagramInfo?.banner_content)}</div>
        </div>
      </StyledBanner>
    )
  }

  return (
    <ReportLayout
      header={
        <Banner
          bannerImage={enneagramInfo?.banner_image !== '' ? enneagramInfo?.banner_image : noImage}
        />
      }
    >
      <Div>
        <div className="research-list">
          <div className="list">
            <div className="label">{enneagramInfo?.body_p1_title}</div>
            <div className="m-desc">{enneagramInfo?.banner_content}</div>
            <img src={enneagramInfo?.banner_image !== '' ? enneagramInfo?.banner_image : noImage} />
            <div className="desc">{ReactHtmlParser(enneagramInfo?.body_p1_content)}</div>
            <div className="m-label-2 ">enneagram คืออะไร ?</div>
            <div className="m-desc">{ReactHtmlParser(enneagramInfo?.body_p1_content)}</div>

            <br />
            <div className="label-2 ">{enneagramInfo?.body_p2_title}</div>
            <div className="desc format-info">
              {ReactHtmlParser(enneagramInfo?.body_p2_content)}
            </div>

            <NextButton className="btn-next" onClick={() => history.push(`/result/${userId}`)}>
              Result
            </NextButton>
          </div>
          <div className="offset"></div>
          <div className="list">
            <div className="label-bottom">{enneagramInfo?.body_highlight}</div>

            <div className="m-label-2 ">{enneagramInfo?.body_p2_title}</div>
            <div className="m-desc format-info">
              {ReactHtmlParser(enneagramInfo?.body_p2_content)}
            </div>

            <NextButton className="m-button" onClick={() => history.push(`/result/${userId}`)}>
              Result
            </NextButton>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}

export default ReportOurPromise
