import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import styled from 'styled-components'

import FullReportLayout from '../../../components/layouts/FullReportLayout'

import imageBanner from '../../../assets/images/report/banner-recommend.png'
import imageNutrition1 from '../../../assets/images/report/nutrition1.png'
import imageNutrition2 from '../../../assets/images/report/nutrition2.png'
import imageSleep1 from '../../../assets/images/report/sleep1.png'
import imageSleep2 from '../../../assets/images/report/sleep2.png'
import imageBehaviors1 from '../../../assets/images/report/behaviors1.png'
import imageBehaviors2 from '../../../assets/images/report/behaviors2.png'
import imageSport1 from '../../../assets/images/report/sport1.png'
import imageSport2 from '../../../assets/images/report/sport2.png'
import imageRoutine1 from '../../../assets/images/report/routine1.png'

import { getRecommendById } from 'redux/actions/report'

const Div = styled.div`
  .head-banner {
    padding: 64px;
    background: #ddebfd;

    > .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 56px;
      line-height: 64px;
      color: var(--off-black);
    }

    > .sub-title {
      max-width: 324px;
      margin-top: 16px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 32px;
      line-height: 40px;
      color: var(--off-black);
    }

    > .desc {
      max-width: 470px;
      margin-top: 32px;
      font-size: 20px;
    }

    > .desc2 {
      width: 320px;
      margin: 176px 0 0 auto;

      .title {
        font-weight: bold;
        font-size: 20px;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
      }
    }
  }

  .banner {
    width: 100%;
  }

  .content-group {
    margin-top: 42px;
    padding-bottom: 42px;
    border-bottom: 2px solid var(--off-black);

    .group-wrapper {
      display: flex;
      justify-content: space-between;
      margin: -8px;

      .group-title {
        flex: 1;
        margin: 8px;
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 48px;
        line-height: 56px;
        color: var(--off-black);
      }

      .group-body {
        flex: 1;
        margin: 8px;
      }

      .body-image {
        display: flex;
        margin: 0 -4px;

        > img {
          margin: 0 4px;
          width: 100%;
          min-width: 0;
        }
      }

      .body-content {
        margin-top: 24px;
      }
    }

    &:last-child {
      border: none;
    }
  }
`

const FullReportRecommendDemo = () => {
  const { customerId } = useParams()
  const dispatch = useDispatch()
  const { itemsRec } = useSelector((state) => state.snp)

  useEffect(() => {
    dispatch(getRecommendById(customerId))
  }, [])

  return (
    <FullReportLayout>
      <Div>
        <div className="head-banner">
          <div className="title">HEALTHY ALWAYS</div>
          <div className="sub-title">Personal recommendation For your better life</div>
          <div className="desc">
            <p>
              Because
              <br />
              ore health is unique
            </p>
            <p>
              Replaced entire wellness routine with customized products, and life has never been
              wasier
            </p>
          </div>
          <div className="desc2">
            <div className="title">
              Healthy Always
              <br />
              รักษาสมดุล เสริมสุขภาพแข็งแรง
            </div>
            <div className="desc">
              เราต่างมีพื้นฐานร่างกายที่ต่างกันไป เพื่อให้คุณ Chavalisa มีร่างกายที่สมดุล แข็งแรง 
              การทานอาหารในปริมาณที่เหมาะกับประสิทธิภาพการดูดซึม นอนหลับที่เพียงพอ
              และออกกําลังในรูปแบบที่ ช่วยเสริมภูมิต้านทาน จะช่วยให้คุณมีสุขภาพโดยรวมแข็งแรง 
            </div>
          </div>
        </div>
        <img className="banner" src={imageBanner} />
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">NUTRITION</div>
            <div className="group-body">
              <div className="body-image">
                <img src={imageNutrition1} />
                <img src={imageNutrition2} />
              </div>
              <div className="body-content">
                <p>
                  อ้างอิงจากผลลัพธ์ประสิทธิภาพการดูดซึมสารอาหารที่คุณ Chalisa อาจดูดซึมได้ไม่เต็มที่
                  คือ
                  <br />
                  Vitamin E<br />
                  DHA
                  <br />
                  VItamin C<br />
                  ดังนั้น การใส่ใจเลือกทานอาหารที่อุดมไปด้วยสารอาหารดังกล่าว อย่าง
                  <br />
                  บลูเบอร์รี่
                  <br />
                  อโวคาโด
                  <br />
                  ปลาแซลมอน
                  <br />
                  เป็นแนวทางที่ช่วยให้คุณ Chalisa ได้รับวิตามินเหล่านั้นได้มากขึ้น
                  <br />
                  ทั้งนี้ ไม่ควรละเลยการทานอาหารที่หลากหลายด้วย เพื่อให้ร่างกายสมดุล
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">SLEEP</div>
            <div className="group-body">
              <div className="body-image">
                <img src={imageSleep1} />
                <img src={imageSleep2} />
              </div>
              <div className="body-content">
                <p>
                  คุณมีแนวโน้มที่จะหลับลึกได้น้อยลง
                  ทําให้เวลาที่ร่างกายจะฟื้นฟูตัวเองในระหว่างการหลับสนิทสั้นลงด้วย 
                </p>
                <p>
                  ดังนั้น ควรเข้านอนให้เร็วขึ้น
                  หรือเพิ่มช่วงเวลานอนเพื่อให้เวลาร่างกายได้พักผ่อนได้มากขึ้น
                  พยามลดหรือเลี่ยงการเล่นโทรศัพท์ก่อนนอน เพราะแสงสีฟ้าจาก
                  หน้าจอโทรศัพท์จะรบกวนช่วงเวลาที่เรากําลังเคลิ้มหลับ 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">BEHAVIORS</div>
            <div className="group-body">
              <div className="body-image">
                <img src={imageBehaviors1} />
                <img src={imageBehaviors2} />
              </div>
              <div className="body-content">
                <p>
                  คุณมีแนวโน้มที่จะหลับลึกได้น้อยลง
                  ทําให้เวลาที่ร่างกายจะฟื้นฟูตัวเองในระหว่างการหลับสนิทสั้นลงด้วย 
                </p>
                <p>
                  ดังนั้น ควรเข้านอนให้เร็วขึ้น
                  หรือเพิ่มช่วงเวลานอนเพื่อให้เวลาร่างกายได้พักผ่อนได้มากขึ้น
                  พยามลดหรือเลี่ยงการเล่นโทรศัพท์ก่อนนอน เพราะแสงสีฟ้าจาก
                  หน้าจอโทรศัพท์จะรบกวนช่วงเวลาที่เรากําลังเคลิ้มหลับ 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">SPORT & FITNESS</div>
            <div className="group-body">
              <div className="body-image">
                <img src={imageSport1} />
                <img src={imageSport2} />
              </div>
              <div className="body-content">
                <p>ออกกําลังกายเพื่อลดนํ้าตาลในเลือดที่เหมาะกับคุณ</p>
                <p>
                  คาร์ดิโอ 5 วันต่อสัปดาห์
                  การเพิ่มปริมาณการออกกําลังกายจะช่วยให้คุณเห็นผลลัพธ์ที่ดียิ่งขึ้น ควรตั้ง
                  เป้าหมายในการออกกําลังกายแบบคาร์ดิโอให้ได้อย่างน้อย 5 วันต่อสัปดาห์
                </p>
                <p>
                  ออกกําลังกายแบบ HIIT ร่วมด้วย การออกกําลังกายแบบ HIIT
                  ซึ่งเป็นการออกกําลังกายแบบหนัก สลับกับเบาจนครบเวลาที่กําหนด
                  จะช่วยให้คุณลดนํ้าตาลในเลือด ได้ดียิ่งขึ้น
                </p>
                <p>ออกกําลังกายแบบใช้แรงต้าน 2-3 วันต่อสัปดาห์ เพื่อสุขภาพองค์รวม ที่ดียิ่งขึ้น</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">Routine</div>
            <div className="group-body">
              <div className="body-image">
                <img src={imageRoutine1} />
              </div>
              <div className="body-content">
                <p>
                  เพื่อให้ในแต่ละวันคุณมีตารางชีวิตที่ส่งเสริมกับสุขภาพโดยรวม คำแนะนำจาก FUSION.
                  คือการแบ่งสัดส่วน ใช้เวลาพักผ่อน 40% ออกกำลังกาย 10% ทานอาหารที่มีประโยชน์ 10%
                </p>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </FullReportLayout>
  )
}

export default FullReportRecommendDemo
