import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import { getCustomer, getTemplate, getDomainList, getReportInfo } from 'redux/actions/report'
import ReportMenuMobile from 'components/layouts/components/ReportMenuMobile'

const Div = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 415px) {
    flex-direction: column;
  }

  > .test-detail {
    flex: 1;

    @media screen and (max-width: 416px) {
      padding-top: 50px;
      max-width: 100%;
      width: 100%;
      text-align: center;
    }

    .m-create-date {
      display: none;
      font-size: 14px;
      margin-bottom: 20px;

      @media screen and (max-width: 416px) {
        display: block;
      }
    }

    .profile-name,
    .test-name,
    .kit-name {
      font-family: 'Roboto', 'Noto Sans Thai';
      text-transform: uppercase;
    }

    .profile-name {
      font-size: 72px;
      line-height: 64px;
      color: var(--off-black);

      @media screen and (max-width: 416px) {
        line-height: 30px;
        font-size: 46px;
        margin-bottom: 30px;
        font-weight: bold;
      }
    }

    .test-name {
      margin-top: 8px;
      font-size: 32px;
      color: var(--text-body);
    }

    .test-summary {
      margin-top: 32px;
      font-size: 20px;
    }

    .test-kit {
      margin-top: 92px;

      @media screen and (max-width: 416px) {
        margin-top: 20px;
      }

      .kit-name {
        font-size: 32px;
        color: var(--off-black);
        font-weight: bold;

        @media screen and (max-width: 416px) {
          font-size: 24px;
          font-weight: normal;
        }
      }

      .kit-data {
        margin-top: 16px;
      }

      .create-date {
        display: block;

        @media screen and (max-width: 416px) {
          display: none;
        }
      }
    }

    .test-kit-2 {
      margin-top: 10px;

      .kit-name {
        font-size: 32px;
        color: #424348;

        @media screen and (max-width: 416px) {
          font-size: 24px;
          line-height: 45px;
          font-weight: bold;
        }
      }

      .kit-data {
        color: #d4d9e1;
        margin-top: 16px;
      }
    }
  }

  > .information {
    max-width: 320px;
    text-align: right;
    margin-top: 360px;
    font-size: 14px;

    @media screen and (max-width: 416px) {
      max-width: 100%;
      margin-top: 80px;
      width: 100%;
      text-align: center;
    }

    > button {
      width: 100%;
      margin-top: 32px;

      @media screen and (max-width: 416px) {
        margin-top: 0;
        border-radius: 8px;
        padding: 15px 0;
      }
    }
  }

  > .m-menu-link {
    margin-top: 60px;
    display: none;
    @media screen and (max-width: 416px) {
      display: block;
    }
  }
`

const Report = () => {
  const { simpleId, templateId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { domainList, userInfo } = useSelector((state) => state.report)

  const { userId } = useParams()
  const [titleTh, setTitleTh] = useState()
  const [titleEn, setTitleEn] = useState()

  useEffect(() => {
    fetchingReportInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.report_name_th) {
      let splitWord = userInfo?.report_name_th.split(' ')
      setTitleTh(splitWord)
    }
  }, [userInfo?.report_name_th])

  useEffect(() => {
    if (userInfo?.report_name_en) {
      let splitWord = userInfo?.report_name_en.split(' ')
      setTitleEn(splitWord)
    }
  }, [userInfo?.report_name_en])

  const fetchingReportInfo = useCallback(() => {
    dispatch(getReportInfo(userId))
  }, [userId])

  useEffect(() => {
    dispatch(getCustomer(simpleId))
    dispatch(getDomainList(simpleId))
  }, [simpleId])

  useEffect(() => {
    console.log('userInfo', userInfo)
  }, [userInfo])

  useEffect(() => {
    dispatch(getTemplate(templateId))
  }, [templateId])

  return (
    <ReportLayout>
      <Div>
        <div className="test-detail">
          <div className="m-create-date">Report Generate: {userInfo?.report_create}</div>

          <div className="profile-name">{userInfo?.fname}</div>
          <div className="profile-name">{userInfo?.lname}</div>
          {domainList.lenght > 0 ? (
            <div className="test-summary">
              {domainList.map((item, index) => (
                <div key={index}>
                  {item?.total} {item?.category_original}
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          <div className="test-kit-2">
            <div className="kit-name">{`${titleEn?.[0]} ${titleEn?.[1]} ${titleEn?.[2]}`}</div>
            <div className="kit-name">{`${titleEn?.[3]} ${titleEn?.[4]} ${titleEn?.[5]} ${titleEn?.[6]}`}</div>
          </div>
          <div className="test-kit">
            <div className="kit-name">{titleTh?.[0]}</div>
            <div className="kit-name">{`${titleTh?.[1]} ${titleTh?.[2]}`}</div>
            <div className="create-date">Report Generate: {userInfo?.report_create}</div>
          </div>
        </div>
        <div className="information">
          <NextButton onClick={() => history.push(`/personality/${userId}`)}>
            Personality for better life
          </NextButton>
        </div>

        <div className="m-menu-link">
          <ReportMenuMobile />
        </div>
      </Div>
    </ReportLayout>
  )
}

export default Report
