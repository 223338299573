import { useDispatch } from 'react-redux'
import { useEffect, useCallback } from 'react'
import {
  BrowserRouter as Router,
  useHistory,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom'

import Login from '../pages/Login'
import Logout from '../pages/Logout'
import SecureRoute from './SecureRoute'
import PageNotFound from './PageNotFound'
import Report from '../pages/report/Report'
import Enneagram from '../pages/report/Enneagram'
import Personality from '../pages/report/Personality'
import Result from '../pages/report/Result'
import Recommentdation from '../pages/report/Recommentdation'
import ReportBookOfLife from '../pages/report/ReportBookOfLife'
import ReportSnapshotHealth from '../pages/report/ReportSnapshotHealth'
import ReportSpeacialConsult from '../pages/report/ReportSpeacialConsult'
import ReportNewJourney from '../pages/report/ReportNewJourney'
import FullReportCategory from '../pages/report/fullReport/FullReportCategory'
import FullReportSubCategory from '../pages/report/fullReport/FullReportSubCategory'
import FullReportRecommend from '../pages/report/fullReport/FullReportRecommend'
import FullReportRecommendDemo from '../pages/report/fullReport/FullReportRecommendDemo'

import { login } from 'redux/actions/auth'

const ScrollToTop = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const accessToken = localStorage.getItem('access_token')
  const dispatch = useDispatch()

  useEffect(() => {
    // handleLogin()
  }, [])

  useEffect(() => {
    if (accessToken) {
      //do get api for master data
      // masterDataFetch()
    }
  }, [accessToken, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // const handleLogin = useCallback(() => {
  //   dispatch(login()).then(() => {
  //     history.replace('/report/1')
  //   })
  // }, [dispatch])

  return null
}

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <Route path="/report/:userId?" component={Report} exact />
        {/* <Route path="/full-report/recommend/:customerId" component={FullReportRecommend} exact />
        <Route path="/full-report/recommenddemo" component={FullReportRecommendDemo} exact />
        <Route path="/full-report/:category" component={FullReportCategory} exact /> */}
        {/* <Route
          path="/full-report/:category/:subCategory/:detailId"
          component={FullReportSubCategory}
          exact
        /> */}
        <Route path="/personality/:userId?" component={Personality} exact />
        <Route path="/enneagram/:userId?" component={Enneagram} exact />
        <Route path="/result/:userId?" component={Result} exact />
        <Route path="/recommend/:userId?" component={Recommentdation} exact />
        {/* <Route path="/book-of-life/:simpleId?" component={ReportBookOfLife} exact />
        <Route path="/snapshot-your-health/:simpleId?" component={ReportSnapshotHealth} exact />
        <Route path="/special-consult" component={ReportSpeacialConsult} exact />
        <Route path="/start-new-journey" component={ReportNewJourney} exact /> */}
        <Route path="/page-not-found" component={PageNotFound} exact />
        <Redirect path="*" to="/page-not-found" />
      </Switch>
    </Router>
  )
}

export default Routes
