import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  row-gap: 20px;
  a {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 1.1rem;
      color: #667085;
      text-transform: uppercase;
    }

    .icon-link {
    }
  }
`

const ReportMenuMobile = ({ isNavMenu = false }) => {
  const { userInfo, enneagramInfo } = useSelector((state) => state.report)
  const { userId } = useParams()

  return (
    <Div>
      {isNavMenu ? (
        <NavLink to={`/report/${userId}`} activeClassName="active" exact>
          <span>
            {userInfo?.fname} {userInfo?.lname?.substring(0, 1)}.
          </span>
          {!isNavMenu ? <FontAwesomeIcon color="#667085" icon={['fas', 'arrow-right']} /> : ''}
        </NavLink>
      ) : (
        ''
      )}

      <NavLink to={`/personality/${userId}`} activeClassName="active">
        <span>enneagram of Personality for better life</span>
        {!isNavMenu ? <FontAwesomeIcon color="#667085" icon={['fas', 'arrow-right']} /> : ''}
      </NavLink>
      <NavLink to={`/enneagram/${userId}`} activeClassName="active">
        <span> {enneagramInfo?.banner_title}</span>
        {!isNavMenu ? <FontAwesomeIcon color="#667085" icon={['fas', 'arrow-right']} /> : ''}
      </NavLink>
      <NavLink to={`/result/${userId}`} activeClassName="active">
        <span> Your result</span>
        {!isNavMenu ? <FontAwesomeIcon color="#667085" icon={['fas', 'arrow-right']} /> : ''}
      </NavLink>
      <NavLink to={`/recommend/${userId}`} activeClassName="active">
        <span> Life & Recommendation</span>
        {!isNavMenu ? <FontAwesomeIcon color="#667085" icon={['fas', 'arrow-right']} /> : ''}
      </NavLink>
    </Div>
  )
}

export default ReportMenuMobile
