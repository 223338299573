const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
})

export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const LOGIN = genRequest('LOGIN')

export const GET_RECOMMEND_BY_ID = genRequest('GET_RECOMMEND_BY_ID')

export const GET_PAGE_OUR_PROMISE = genRequest('GET_PAGE_OUR_PROMISE')
export const GET_PAGE_EXPLORE_OUR_BODY = genRequest('GET_PAGE_EXPLORE_OUR_BODY')
export const GET_PAGE_OUR_HUMAN_BODY = genRequest('GET_PAGE_OUR_HUMAN_BODY')
export const GET_PAGE_DISCOVER = genRequest('GET_PAGE_DISCOVER')
export const GET_CUSTOMER = genRequest('GET_CUSTOMER')
export const GET_TEMPLATE = genRequest('GET_TEMPLATE')
export const GET_DOMAIN_LIST = genRequest('GET_DOMAIN_LIST')
export const GET_A_BOOK_OF_LIFE = genRequest('GET_A_BOOK_OF_LIFE')
export const GET_SNAPSHOT = genRequest('GET_SNAPSHOT')
export const GET_SNAPSHOT_DETAIL = genRequest('GET_SNAPSHOT_DETAIL')
export const GET_DOMIAN_FULL_REPORT = genRequest('GET_DOMIAN_FULL_REPORT')
export const GET_REPORT_INFO = genRequest('GET_REPORT_INFO')
