import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import styled from 'styled-components'

import FullReportLayout from '../../../components/layouts/FullReportLayout'

import imageBanner from '../../../assets/images/report/banner-recommend.png'
import imageNutrition1 from '../../../assets/images/report/nutrition1.png'
import imageNutrition2 from '../../../assets/images/report/nutrition2.png'
import imageSleep1 from '../../../assets/images/report/sleep1.png'
import imageSleep2 from '../../../assets/images/report/sleep2.png'
import imageBehaviors1 from '../../../assets/images/report/behaviors1.png'
import imageBehaviors2 from '../../../assets/images/report/behaviors2.png'
import imageSport1 from '../../../assets/images/report/sport1.png'
import imageSport2 from '../../../assets/images/report/sport2.png'
import imageRoutine1 from '../../../assets/images/report/routine1.png'

import { getRecommendById } from 'redux/actions/report'

const Div = styled.div`
  .head-banner {
    padding: 64px;
    background: #ddebfd;

    > .title {
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 56px;
      line-height: 64px;
      color: var(--off-black);
    }

    > .sub-title {
      max-width: 324px;
      margin-top: 16px;
      font-family: 'kelson_sansbold';
      text-transform: uppercase;
      font-size: 32px;
      line-height: 40px;
      color: var(--off-black);
    }

    > .desc {
      max-width: 470px;
      margin-top: 32px;
      font-size: 20px;
    }

    > .desc2 {
      width: 320px;
      margin: 176px 0 0 auto;

      .title {
        font-weight: bold;
        font-size: 20px;
        color: var(--off-black);
      }

      .desc {
        margin-top: 16px;
      }
    }
  }

  .banner {
    width: 100%;
  }

  .content-group {
    margin-top: 42px;
    padding-bottom: 42px;
    border-bottom: 2px solid var(--off-black);

    .group-wrapper {
      display: flex;
      justify-content: space-between;
      margin: -8px;

      .group-title {
        flex: 1;
        margin: 8px;
        font-family: 'kelson_sansbold';
        text-transform: uppercase;
        font-size: 48px;
        line-height: 56px;
        color: var(--off-black);
      }

      .group-body {
        flex: 1;
        margin: 8px;
      }

      .body-image {
        display: flex;
        margin: 0 -4px;

        > img {
          margin: 0 4px;
          width: 100%;
          min-width: 0;
        }
      }

      .body-content {
        margin-top: 24px;
        .content-title {
          flex: 1;
          font-family: 'kelson_sansbold';
          font-size: 24px;
          line-height: 30px;
          color: var(--off-black);
        }
      }
    }

    &:last-child {
      border: none;
    }
  }
`

const FullReportRecommend = () => {
  const { customerId } = useParams()
  const dispatch = useDispatch()
  const { recommendReport } = useSelector((state) => state.snp)

  useEffect(() => {
    dispatch(getRecommendById(customerId))
  }, [])

  return (
    <FullReportLayout>
      <Div>
        <div className="head-banner">
          <div className="title">HEALTHY ALWAYS</div>
          <div className="sub-title">Personal recommendation For your better life</div>
          <div className="desc">
            <p>
              Because
              <br />
              ore health is unique
            </p>
            <p>
              Replaced entire wellness routine with customized products, and life has never been
              wasier
            </p>
          </div>
          <div className="desc2">
            <div className="title">
              Healthy Always
              <br />
              รักษาสมดุล เสริมสุขภาพแข็งแรง
            </div>
            <div className="desc">
              เราต่างมีพื้นฐานร่างกายที่ต่างกันไป เพื่อให้คุณ Chavalisa มีร่างกายที่สมดุล แข็งแรง 
              การทานอาหารในปริมาณที่เหมาะกับประสิทธิภาพการดูดซึม นอนหลับที่เพียงพอ
              และออกกําลังในรูปแบบที่ ช่วยเสริมภูมิต้านทาน จะช่วยให้คุณมีสุขภาพโดยรวมแข็งแรง 
            </div>
          </div>
        </div>
        <img className="banner" src={imageBanner} />
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">NUTRITION</div>
            <div className="group-body">
              <div className="body-image">
                <img src={recommendReport?.nutrition_img1} />
                <img src={recommendReport?.nutrition_img2} />
              </div>
              <div className="body-content">
                <p className="content-title">{recommendReport?.nutrition_title_eng}</p>
                <p>{recommendReport?.nutrition_detail_eng}</p>
                <p className="content-title">{recommendReport?.nutrition_title_thai}</p>
                <p>{recommendReport?.nutrition_detail_thai}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">SLEEP</div>
            <div className="group-body">
              <div className="body-image">
                <img src={recommendReport?.sleep_img1} />
                <img src={recommendReport?.sleep_img2} />
              </div>
              <div className="body-content">
                <p className="content-title">{recommendReport?.sleep_title_eng}</p>
                <p>{recommendReport?.sleep_detail_eng}</p>
                <p className="content-title">{recommendReport?.sleep_title_thai}</p>
                <p>{recommendReport?.sleep_detail_thai}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">BEHAVIORS</div>
            <div className="group-body">
              <div className="body-image">
                <img src={recommendReport?.behaviors_img1} />
                <img src={recommendReport?.behaviors_img2} />
              </div>
              <div className="body-content">
                <p className="content-title">{recommendReport?.behaviors_title_eng}</p>
                <p>{recommendReport?.behaviors_detail_eng}</p>
                <p className="content-title">{recommendReport?.behaviors_title_thai}</p>
                <p>{recommendReport?.behaviors_detail_thai}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">SPORT & FITNESS</div>
            <div className="group-body">
              <div className="body-image">
                <img src={recommendReport?.sportfit_img1} />
                <img src={recommendReport?.sportfit_img2} />
              </div>
              <div className="body-content">
                <p className="content-title">{recommendReport?.sportfit_title_eng}</p>
                <p>{recommendReport?.sportfit_detail_eng}</p>
                <p className="content-title">{recommendReport?.sportfit_title_thai}</p>
                <p>{recommendReport?.sportfit_detail_thai}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="group-wrapper">
            <div className="group-title">Routine</div>
            <div className="group-body">
              <div className="body-image">
                <img src={recommendReport?.routine_img1} />
              </div>
              <div className="body-content">
                <p className="content-title">{recommendReport?.routine_title_eng}</p>
                <p>{recommendReport?.routine_detail_eng}</p>
                <p className="content-title">{recommendReport?.routine_title_thai}</p>
                <p>{recommendReport?.routine_detail_thai}</p>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </FullReportLayout>
  )
}

export default FullReportRecommend
