import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import ReportLayout from '../../components/layouts/ReportLayout'
import NextButton from '../../components/common/button/NextButton'

import noImage from '../../assets/images/report/no-image.png'

import { getReportInfo } from 'redux/actions/report'

const Div = styled.div`
  margin: 0 -64px;
  font-family: 'kelson_sansbold';

  .container {
    margin: 0 auto;
    max-width: 1440px;
    padding: 80px;

    @media screen and (max-width: 416px) {
      padding: 50px 60px;
      width: 100%;
    }
  }

  .headline {
    display: flex;
    justify-content: center;
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media screen and (max-width: 416px) {
      font-size: 16px;
    }
  }

  .sub-headline {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-family: 'Roboto', 'Noto Sans Thai';

    @media screen and (max-width: 416px) {
      text-align: center;
      font-size: 30px;
      margin-bottom: 30px;
      width: 100%;
    }
  }

  .sub-headline-2 {
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 4rem;
    @media screen and (max-width: 416px) {
      text-align: center;
      font-size: 30px;
      width: 100%;
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

    @media screen and (max-width: 416px) {
      margin-bottom: 45px;
    }
    img {
      width: 100%;
      height: 500px;
      object-fit: cover;

      @media screen and (max-width: 416px) {
        height: 275px;
      }
    }
  }

  .result-description {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-family: 'Roboto', 'Noto Sans Thai';

    @media screen and (max-width: 416px) {
      margin-bottom: 30px;
    }
  }

  .result-basic {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-family: 'Roboto', 'Noto Sans Thai';

    @media screen and (max-width: 416px) {
      margin-bottom: 30px;
    }
  }

  .result-key-motivation {
    font-size: 1.25rem;
    margin-bottom: 4rem;
    font-family: 'Roboto', 'Noto Sans Thai';

    @media screen and (max-width: 416px) {
      margin-bottom: 40px;
    }
  }

  .divide {
    border-top: 1px solid var(--off-black);
    margin-bottom: 4rem;

    @media screen and (max-width: 416px) {
      border-top: 1px solid #1717141c;
    }
  }

  .bottom-section {
    display: flex;

    @media screen and (max-width: 416px) {
      flex-direction: column;
    }

    .result-sec-2-title {
      font-size: 3rem;
      max-width: 80%;
      font-weight: bold;
      font-family: 'Roboto', 'Noto Sans Thai';

      @media screen and (max-width: 416px) {
        font-size: 28px;
        width: 70%;
        margin-bottom: 20px;
      }
    }

    .result-sec2-p-title {
      font-family: 'Roboto', 'Noto Sans Thai';
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .result-sec2-p-content {
      font-size: 1.25rem;
      margin-bottom: 2.5rem;
      font-family: 'Roboto', 'Noto Sans Thai';
    }
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    margin: 0 64px;
    font-family: 'kelson_sansbold';
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;

    @media screen and (max-width: 416px) {
      margin: 0;
    }
  }

  button {
    width: 100%;
    margin-top: 62px;
    /* display: none; */
    border-radius: 8px;
    /* border: 1px solid #4a03ee; */

    @media screen and (max-width: 416px) {
      display: block;
    }
  }
`

const StyledBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 720px;
  background: ${(props) => `url(${props.bannerImage} ) no-repeat center center`};
  background-position: center center;
  background-size: cover;
  color: var(--off-white);
  position: relative;

  .title {
    position: absolute;
    top: 25%;
    left: 15%;
    font-family: 'kelson_sansbold';
    text-transform: uppercase;
    font-size: 56px;
    line-height: 64px;
    max-width: 558px;
  }

  .desc {
    position: absolute;
    top: 35%;
    right: 20%;
    max-width: 320px;
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const ReportOurHumanBody = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { resultInfo } = useSelector((state) => state.report)
  const { userId } = useParams()

  useEffect(() => {
    fetchingReportInfo()
  }, [])

  const fetchingReportInfo = useCallback(() => {
    dispatch(getReportInfo(userId))
  }, [userId])

  useEffect(() => {
    console.log('resultInfo', resultInfo)
  }, [resultInfo])
  // const Banner = ({ bannerImage }) => {
  //   const firstWord = ourHumanBodyReport?.header_topic?.split(' ').shift()
  //   const secondWord = removeFirstWord(ourHumanBodyReport?.header_topic)

  //   return (
  //     <StyledBanner bannerImage={bannerImage}>
  //       <div className="title">
  //         {firstWord}
  //         <br />
  //         {secondWord}
  //       </div>
  //       <div className="desc">{ourHumanBodyReport?.topic}</div>
  //     </StyledBanner>
  //   )
  // }

  return (
    // <ReportLayout header={<Banner bannerImage={ourHumanBodyReport?.image_topic || noImage} />}>
    <ReportLayout>
      <Div>
        <div className="container">
          <div className="headline">Your Result</div>
          <div className="sub-headline">{resultInfo?.result}</div>
          <div className="sub-headline-2">{resultInfo?.result_character}</div>
          <div className="img-container">
            <img src={resultInfo?.result_image !== '' ? resultInfo?.result_image : noImage} />
          </div>
          <div className="result-description">
            {ReactHtmlParser(resultInfo?.result_description)}
          </div>
          <div className="result-basic">{ReactHtmlParser(resultInfo?.result_basic_fear)}</div>
          <div className="result-basic">{ReactHtmlParser(resultInfo?.result_basic_desire)}</div>
          <div className="result-key-motivation">
            {ReactHtmlParser(resultInfo?.result_key_motivation)}
          </div>
          <div className="divide"></div>

          <div className="bottom-section">
            <div className="left">
              <div className="result-sec-2-title">{resultInfo?.result_sec2_title}</div>
            </div>
            <div className="right">
              <div className="result-sec2-p-title">{resultInfo?.result_sec2_p1_title}</div>
              <div className="result-sec2-p-content format-info">
                {ReactHtmlParser(resultInfo?.result_sec2_p1_content)}
              </div>
              <div className="result-sec2-p-title">{resultInfo?.result_sec2_p2_title}</div>
              <div className="result-sec2-p-content format-info">
                {ReactHtmlParser(resultInfo?.result_sec2_p2_content)}
              </div>
              <div className="result-sec2-p-title">{resultInfo?.result_sec2_p3_title}</div>
              <div className="result-sec2-p-content format-info">
                {ReactHtmlParser(resultInfo?.result_sec2_p3_content)}
              </div>
              <NextButton onClick={() => history.push(`/recommend/${userId}`)}>
                Life & Recommendation
              </NextButton>
            </div>
          </div>
        </div>
      </Div>
    </ReportLayout>
  )
}
export default ReportOurHumanBody
