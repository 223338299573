import {
  GET_RECOMMEND_BY_ID,
  GET_PAGE_OUR_PROMISE,
  GET_PAGE_EXPLORE_OUR_BODY,
  GET_PAGE_OUR_HUMAN_BODY,
  GET_PAGE_DISCOVER,
  GET_CUSTOMER,
  GET_TEMPLATE,
  GET_DOMAIN_LIST,
  GET_A_BOOK_OF_LIFE,
  GET_SNAPSHOT,
  GET_SNAPSHOT_DETAIL,
  GET_DOMIAN_FULL_REPORT,
  GET_REPORT_INFO,
} from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading'
import { getAPI, postAPI, patchAPI, deleteAPI } from 'utils/api'

export const getRecommendById = (customer_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_RECOMMEND_BY_ID.REQUEST })
  return getAPI({
    url: 'recommendation',
    params: {
      customer_id,
    },
  })
    .then(({ data }) =>
      dispatch({
        type: GET_RECOMMEND_BY_ID.SUCCESS,
        payload: { data: data[0] },
      })
    )
    .catch((error) => dispatch({ type: GET_RECOMMEND_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getPageOurPromise = (data) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_PAGE_OUR_PROMISE.REQUEST })
  return getAPI({
    url: 'pageManagement/page_our_promise',
    params: {
      template_id: data?.template_id,
      page: data?.page,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_PAGE_OUR_PROMISE.SUCCESS,
        payload: { data: data },
      })
    })
    .catch((error) => dispatch({ type: GET_PAGE_OUR_PROMISE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getPageExploreOurBody = (data) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_PAGE_EXPLORE_OUR_BODY.REQUEST })
  return getAPI({
    url: 'pageManagement/page_explore_our_body',
    params: {
      template_id: data?.template_id,
      page: data?.page,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: GET_PAGE_EXPLORE_OUR_BODY.SUCCESS,
        payload: { exploreOurBodyReport: data[0] },
      })
    })
    .catch((error) => dispatch({ type: GET_PAGE_EXPLORE_OUR_BODY.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getPageOurHumanBody = (data) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_PAGE_OUR_HUMAN_BODY.REQUEST })
  return getAPI({
    url: 'pageManagement/page_our_human_body',
    params: {
      template_id: data?.template_id,
      page: data?.page,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: GET_PAGE_OUR_HUMAN_BODY.SUCCESS,
        payload: { ourHumanBodyReport: data[0] },
      })
    })
    .catch((error) => dispatch({ type: GET_PAGE_OUR_HUMAN_BODY.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getPageDiscover = (data) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_PAGE_DISCOVER.REQUEST })
  return getAPI({
    url: 'pageManagement/page_discover_yourself',
    params: {
      template_id: data?.template_id,
      page: data?.page,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: GET_PAGE_DISCOVER.SUCCESS,
        payload: { discoverReport: data[0] },
      })
    })
    .catch((error) => dispatch({ type: GET_PAGE_DISCOVER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getCustomer = (simple_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_CUSTOMER.REQUEST })
  return getAPI({
    url: '/view_customers',
    params: {
      $limit: 10,
      $skip: 0,
      simple_id,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: GET_CUSTOMER.SUCCESS,
        payload: { customer: data?.find((it) => it.simple_id === simple_id) },
      })
    })
    .catch((error) => dispatch({ type: GET_CUSTOMER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}
export const getTemplate = (template_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_TEMPLATE.REQUEST })
  return getAPI({
    url: `/pageManagement/page_template/${template_id}`,
  })
    .then((data) => {
      dispatch({
        type: GET_TEMPLATE.SUCCESS,
        payload: { template: data },
      })
    })
    .catch((error) => dispatch({ type: GET_TEMPLATE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getDomainList = (simple_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_DOMAIN_LIST.REQUEST })
  return getAPI({
    url: `/pageManagement/domainTotal`,
    params: {
      simple_id,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: GET_DOMAIN_LIST.SUCCESS,
        payload: { domainList: data },
      })
    })
    .catch((error) => dispatch({ type: GET_DOMAIN_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getABookOfLife = (simple_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_A_BOOK_OF_LIFE.REQUEST })
  return getAPI({
    url: `/pageManagement/book_of_life`,
    params: {
      simple_id,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_A_BOOK_OF_LIFE.SUCCESS,
        payload: { bookOfLifeReport: data },
      })
    })
    .catch((error) => dispatch({ type: GET_A_BOOK_OF_LIFE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getSnapshotYourHealth = (simple_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_SNAPSHOT.REQUEST })
  return getAPI({
    url: `/pageManagement/snapshot_your_health`,
    params: {
      simple_id,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_SNAPSHOT.SUCCESS,
        payload: { snapshotReport: data },
      })
    })
    .catch((error) => dispatch({ type: GET_SNAPSHOT.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getSnapshotYourHealthDetail = (simple_id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_SNAPSHOT_DETAIL.REQUEST })
  return getAPI({
    url: `/pageManagement/snapshot_your_health_detail`,
    params: {
      simple_id,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_SNAPSHOT_DETAIL.SUCCESS,
        payload: { snapshotDetailReport: data },
      })
    })
    .catch((error) => dispatch({ type: GET_SNAPSHOT_DETAIL.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}

export const getDomainFullReport =
  (simple_id = 'MM05GT52C', domain, dtm_id) =>
  (dispatch, getState) => {
    dispatch(showLoading())
    dispatch({ type: GET_DOMIAN_FULL_REPORT.REQUEST })
    return getAPI({
      url: `/pageManagement/domain_full_report`,
      params: {
        simple_id,
        dtm_id,
        domain,
      },
    })
      .then((data) => {
        dispatch({
          type: GET_DOMIAN_FULL_REPORT.SUCCESS,
          payload: { fullReport: data },
        })
      })
      .catch((error) => dispatch({ type: GET_DOMIAN_FULL_REPORT.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading())
      })
  }

export const getReportInfo = (id) => (dispatch, getState) => {
  dispatch(showLoading())
  dispatch({ type: GET_REPORT_INFO.REQUEST })
  return getAPI({
    url: `/tester/preassessmentreport_result/${id}`,
  })
    .then((data) => {
      const { user_info, enneagram_info, personality_info, result_info, recommend_info } = data

      localStorage.setItem('user_info', JSON.stringify(user_info))
      localStorage.setItem('enneagram_info', JSON.stringify(enneagram_info))
      localStorage.setItem('personality_info', JSON.stringify(personality_info))
      localStorage.setItem('result_info', JSON.stringify(result_info))
      localStorage.setItem('recommend_info', JSON.stringify(recommend_info))

      dispatch({
        type: GET_REPORT_INFO.SUCCESS,
        payload: {
          userInfo: user_info,
          enneagramInfo: enneagram_info,
          personalityInfo: personality_info,
          resultInfo: result_info,
          recommendInfo: recommend_info,
        },
      })
    })
    .catch((error) => dispatch({ type: GET_REPORT_INFO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading())
    })
}
