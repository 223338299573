import {
  GET_RECOMMEND_BY_ID,
  GET_PAGE_OUR_PROMISE,
  GET_PAGE_EXPLORE_OUR_BODY,
  GET_PAGE_OUR_HUMAN_BODY,
  GET_PAGE_DISCOVER,
  GET_CUSTOMER,
  GET_TEMPLATE,
  GET_DOMAIN_LIST,
  GET_A_BOOK_OF_LIFE,
  GET_SNAPSHOT,
  GET_SNAPSHOT_DETAIL,
  GET_DOMIAN_FULL_REPORT,
  GET_REPORT_INFO,
} from 'redux/actionTypes'

const initialState = {
  fetching: false,
  saving: false,
  total: 0,
  limit: 20,
  skip: 0,
  items: [],
  recommendReport: {},
  ourPromiseReport: {},
  exploreOurBodyReport: {},
  ourHumanBodyReport: {},
  customer: {},
  template: {},
  domainList: [],
  bookOfLifeReport: {},
  snapshotReport: {},
  snapshotDetailReport: {},
  discoverReport: {},
  fullReport: {},
  userInfo: JSON.parse(localStorage.getItem('userInfo')),
  enneagramInfo: JSON.parse(localStorage.getItem('enneagramInfo')),
  personalityInfo: JSON.parse(localStorage.getItem('personalityInfo')),
  resultInfo: JSON.parse(localStorage.getItem('resultInfo')),
  recommendInfo: JSON.parse(localStorage.getItem('recommendInfo')),
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_RECOMMEND_BY_ID.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_RECOMMEND_BY_ID.SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        fetching: true,
        recommendReport: data,
      }
    }
    case GET_RECOMMEND_BY_ID.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_PAGE_OUR_PROMISE.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_PAGE_OUR_PROMISE.SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        fetching: true,
        ourPromiseReport: data,
      }
    }
    case GET_PAGE_OUR_PROMISE.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_PAGE_EXPLORE_OUR_BODY.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_PAGE_EXPLORE_OUR_BODY.SUCCESS: {
      const { exploreOurBodyReport } = action.payload
      return {
        ...state,
        fetching: true,
        exploreOurBodyReport,
      }
    }
    case GET_PAGE_EXPLORE_OUR_BODY.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_PAGE_OUR_HUMAN_BODY.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_PAGE_OUR_HUMAN_BODY.SUCCESS: {
      const { ourHumanBodyReport } = action.payload
      return {
        ...state,
        fetching: true,
        ourHumanBodyReport,
      }
    }
    case GET_PAGE_OUR_HUMAN_BODY.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_PAGE_DISCOVER.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_PAGE_DISCOVER.SUCCESS: {
      const { discoverReport } = action.payload
      return {
        ...state,
        fetching: true,
        discoverReport,
      }
    }
    case GET_PAGE_DISCOVER.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_CUSTOMER.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_CUSTOMER.SUCCESS: {
      const { customer } = action.payload
      return {
        ...state,
        fetching: true,
        customer,
      }
    }
    case GET_CUSTOMER.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_TEMPLATE.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_TEMPLATE.SUCCESS: {
      const { template } = action.payload
      return {
        ...state,
        fetching: true,
        template,
      }
    }
    case GET_TEMPLATE.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_DOMAIN_LIST.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_DOMAIN_LIST.SUCCESS: {
      const { domainList } = action.payload
      return {
        ...state,
        fetching: true,
        domainList,
      }
    }
    case GET_DOMAIN_LIST.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_A_BOOK_OF_LIFE.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_A_BOOK_OF_LIFE.SUCCESS: {
      const { bookOfLifeReport } = action.payload
      return {
        ...state,
        fetching: true,
        bookOfLifeReport,
      }
    }
    case GET_A_BOOK_OF_LIFE.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_SNAPSHOT.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_SNAPSHOT.SUCCESS: {
      const { snapshotReport } = action.payload
      return {
        ...state,
        fetching: true,
        snapshotReport,
      }
    }
    case GET_SNAPSHOT.FAILURE:
      return {
        ...state,
        fetching: false,
      }

    case GET_SNAPSHOT_DETAIL.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_SNAPSHOT_DETAIL.SUCCESS: {
      const { snapshotDetailReport } = action.payload
      return {
        ...state,
        fetching: true,
        snapshotDetailReport,
      }
    }
    case GET_SNAPSHOT_DETAIL.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_DOMIAN_FULL_REPORT.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_DOMIAN_FULL_REPORT.SUCCESS: {
      const { fullReport } = action.payload
      return {
        ...state,
        fetching: true,
        fullReport,
      }
    }
    case GET_DOMIAN_FULL_REPORT.FAILURE:
      return {
        ...state,
        fetching: false,
      }
    case GET_REPORT_INFO.REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case GET_REPORT_INFO.SUCCESS: {
      const { userInfo, enneagramInfo, personalityInfo, resultInfo, recommendInfo } = action.payload
      return {
        ...state,
        fetching: true,
        userInfo,
        enneagramInfo,
        personalityInfo,
        resultInfo,
        recommendInfo,
      }
    }
    case GET_REPORT_INFO.FAILURE:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}
